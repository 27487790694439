

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

import { DxForm, DxItem, DxLabel } from 'devextreme-vue/form';
import ProductSelectBox from './product-select-box.vue';

import DxButton from 'devextreme-vue/button';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSummary,
  DxTotalItem,
  DxValueFormat,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxToolbar
} from 'devextreme-vue/data-grid';

import { taskdetails } from './../data/data.js';

const dataGridRef = 'dataGrid';

export default {
  components: {
    DxButton,
    DxForm, DxItem, DxLabel, ProductSelectBox,  DxDataGrid,
    DxColumn,
    DxPaging,
    DxSummary,
    DxTotalItem,
    DxValueFormat,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxToolbar,    
  },
  props: {
    supplierId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      chosenProductId: null,
      taskdetail: taskdetails,
      dataGridRef,
    };
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance;
    },
  },
  methods: {
    exportGrid() {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: this.dataGrid,
      }).then(() => {
        doc.save('ReporteServicio.pdf');
      });
    },
  },
};
